import { defineComponent } from 'vue';
import { activeConfig } from '@/config';
import SyncDataWarehouseDialog from '@/components/SyncDataWarehouseDialog.vue';
export default defineComponent({
    components: {
        SyncDataWarehouseDialog
    },
    data() {
        return {
            confirmTransformDialog: false,
            options: ['PROCESSOR', 'ANNUAL ENVIRONMENT', 'QUARTERLY PRODUCTION', 'ANNUAL PRODUCTION', 'QUARTERLY HUMAN RESOURCE', 'ANNUAL HUMAN RESOURCE', 'DROPDOWN ITEM'],
            selectedOption: 'PROCESSOR'
        };
    },
    created() {
        this.checkAuth();
    },
    mounted() {
        this.currentRoute();
    },
    computed: {
        authenticated() {
            return this.$store.state.authenticated;
        },
        logoutLink() {
            return activeConfig().apiBaseUrl + '/oauth/logout';
        },
        loginLink() { return activeConfig().apiBaseUrl + '/oauth/login/cognito'; },
        loginPage() {
            if (this.$route.name === 'login') {
                return true;
            }
            else {
                return false;
            }
        }
    },
    watch: {
        selectedOption(val) {
            switch (val) {
                case 'PROCESSOR':
                    this.$router.push({ name: 'Processors' });
                    break;
                case 'ANNUAL ENVIRONMENT':
                    this.$router.push({ name: 'Annual Environments' });
                    break;
                case 'QUARTERLY PRODUCTION':
                    this.$router.push({ name: 'Quartile Productions' });
                    break;
                case 'ANNUAL PRODUCTION':
                    this.$router.push({ name: 'Annual Productions' });
                    break;
                case 'ANNUAL HUMAN RESOURCE':
                    this.$router.push({ name: 'Annual Human Resources' });
                    break;
                case 'QUARTERLY HUMAN RESOURCE':
                    this.$router.push({ name: 'Quartile Human Resources' });
                    break;
                case 'DROPDOWN ITEM':
                    this.$router.push({ name: 'Dropdown Items' });
                    break;
                default: break;
            }
        }
    },
    methods: {
        goTo(location) {
            this.$router.replace(location);
        },
        async checkAuth() {
            ('check auth');
            try {
                await this.$store.dispatch('checkAuth');
            }
            catch (error) {
                console.log('MainLayout : checkAuth failed', error);
            }
        },
        currentRoute() {
            if (this.$route.name === 'Processor') {
                this.selectedOption = 'PROCESSOR';
            }
            else if (this.$route.name === 'Annual Environments') {
                this.selectedOption = 'ANNUAL ENVIRONMENT';
            }
            else if (this.$route.name === 'Annual Productions') {
                this.selectedOption = 'ANNUAL PRODUCTION';
            }
            else if (this.$route.name === 'Quartile Productions') {
                this.selectedOption = 'QUARTERLY PRODUCTION';
            }
            else if (this.$route.name === 'Annual Human Resources') {
                this.selectedOption = 'ANNUAL HUMAN RESOURCE';
            }
            else if (this.$route.name === 'Quartile Human Resources') {
                this.selectedOption = 'QUARTERLY HUMAN RESOURCE';
            }
            else if (this.$route.name === 'Dropdown Items') {
                this.selectedOption = 'DROPDOWN ITEM';
            }
        }
    }
});
