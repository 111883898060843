import { render } from "./SyncDataWarehouseDialog.vue?vue&type=template&id=51b03bcc&scoped=true"
import script from "./SyncDataWarehouseDialog.vue?vue&type=script&lang=ts"
export * from "./SyncDataWarehouseDialog.vue?vue&type=script&lang=ts"

import "./SyncDataWarehouseDialog.vue?vue&type=style&index=0&id=51b03bcc&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-51b03bcc"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog,QCard,QCardSection,QTable,QCardActions});qInstall(script, 'directives', {ClosePopup});
